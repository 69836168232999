@import "../scss-variables";

.contact-container {
    display: flex;
    flex-direction: column-reverse;
    padding: 50px 0;

    h2 {
        font-size: 2rem;
    }

    .contact-text {
        text-align: center;
        padding: 25px;
        margin: 0 auto;
    }
}

.contact {
    display: flex;
    flex-direction: column;
    padding: 15px;


    input,
    textarea {
        width: 100%;
        padding: 10px;
        margin: 5px 0;
    }

    label {
        padding: 15px 0 0;
        font-weight: bold;
    }

    textarea {
        min-height: 200px;
    }

    .button {
        padding: 25px;
        font-size:1.6rem;
    }
}



@media (min-width: 768px) {
    .contact-container {
        align-items: center;
        flex-direction: row;

        form {
            width: 50%;
        }
    }
 }
 
 
 @media (min-width: 1400px) {
    .contact-container {
        margin: 0 auto;
        max-width: 1400px;
        padding: 100px 0;
    }
     
 }