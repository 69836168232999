@import "../scss-variables";

.content-left-container {
    display: flex;
    flex-direction: column-reverse;
    margin: 15px;
    padding: 15px;

    .image {
        background-size: cover;
        height: 400px;
    }
    
}




@media (min-width: 768px) {
   .content-left-container {
       flex-direction: row;
       flex-wrap: nowrap;
       justify-content: flex-start;
       margin: 0;
       padding: 0;


       .text-container {
           margin-left: 25px;
           max-width: 40%;
           padding: 50px;
       }

       .image {
           height: 400px;
           margin-right: 25px;
           width: 40%;
       }
   }
}


@media (min-width: 1400px) {
    .content-left-container {
        margin:0 auto;
        max-width: 1400px;
      
        .text-container {
            margin-left: 50px;
            max-width: 50%;
        }
 
        .image {
            height: 500px;
            margin-right: 50px;
            width: 40%;
        }
    }
    
}