@import "../scss-variables";


.modal-holder {
    background: rgba(0,0,0,.75);
    display: none;
    left: 0;
    margin: 0 auto;
    min-height: 100%;
    position: fixed;
    top: 0;
    width: 100%;

    &.show {
        display: block;
    }


    .custom-modal {
        background: $white;
        display: block;
        margin: 2% auto;
        height: 98vh;
        padding:  50px;
        position: relative;
        width: 98vw;

        .modal-content {
            line-height: 1.4rem;
            height: 63vh;
            overflow: auto;
        }

    }


    .button {
            position: absolute;
            bottom: 60px;
            z-index: 999;
            left: 10vw;
        
    }

}

.custom-modal {
    display:none;
}



.article-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 50px 0;
    position: relative;

    .article-description {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        
        h2 {
            text-align: center;
            width: 100%;
        }
        h3 {
            display: block;
            text-align: left;
            justify-content: left;
            width: 100%;
            margin-left: 50px;
        }
        
        p {
            width: 100%;
            text-align: center;
        }
    }

    .article {
        align-items: center;
        background: $primary-color;
        box-shadow: $box-shadow;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin:  5%;
        padding: 50px 25px;
        transition: $transition;

        &> div {
            align-items: center;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 5%;
            padding: 50px 25px;
        }

        &:hover {
            background: darken($primary-color, 10%);
        }
    }

    .article-icon {
        color: $white;
        font-size: 3rem;
        text-align: center;
    }
    
    
    .article-summary {
        color: $white;
    }
    
}

@media (min-width: 768px) {
    .article-container {
        flex-direction: row;
        .article {
            display: flex;
            margin: 3%;
            width: 27%;
            
            h3 {
                text-align: center;
            }
            
        }
    }


.modal-holder {


    .custom-modal {
        height: 92vh;
        width: 85vw;

        .modal-content {
            height: 75vh;
        }

        .button {
            bottom: 80px;
        
    }
    }

}
 }
 
 
 @media (min-width: 1400px) {

    .article-container {
        margin: 0 auto;
        max-width: 1400px;
        padding: 100px 0;
    }
 }