.testimonial-container  {
    display: flex;
    flex-direction: column;
    padding: 15px;

    h2 {
        font-size:2.5rem;
        padding: 25px 0;
        width: 100%;
    }

    .testimonial > div{
        display: flex;
        flex-direction: column;

        .testimonial-image {
            align-items: center;
            display: flex;
            justify-content: center;
            width: 100%;

            img {
                border-radius: 50%;
                height: 300px;
                width: 300px;
            }
        }
    }
}

@media (min-width: 768px) {
    .testimonial-container {
        flex-direction: row;
        flex-wrap: wrap;

        .testimonial {
            width: 50%;
        }
    
    
        .testimonial > div{
            align-items: flex-start;
            flex-direction: row;
            padding: 25px;
    
            .testimonial-image {
                width: 100px;
                
                img {
                    height: 100px;
                    width: 100px;
                }
            }

            .testimonial-content {
                padding: 15px;

                .author {
                    font-style: italic;
                }

            }
        }
    }
}


@media (min-width: 1400px) {
    .testimonial-container {
        max-width: 1400px;
        margin: 0 auto;
    
        .testimonial {
            width: 50%;
            
        }
    }
}