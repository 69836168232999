@import "scss-variables";

* {
  box-sizing: border-box;
}

body {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  line-height: 1.7rem;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "OpenSans", sans-serif;
  line-height: 2.5rem;
}

h2 {
  font-size: 2rem;
}

.button {
  background: $button-bg;
  border: 1px solid $black;
  border-radius: 5px;
  color: $button-text;
  padding: 15px 25px;
  text-decoration: none;
  transition: $transition;

  &:hover {
    background: darken($button-bg, 5%);
    box-shadow: 3px 3px 5px $button-shadow;
    cursor: pointer;
  }
}

.alt-bg {
  background-color: $secondary-color!important;
  box-shadow: $box-shadow;
  color: $white!important;
}

.third-bg {
  background-color: $third-color!important;
  box-shadow: $box-shadow;
  color: $black!important;
}

.App {
  scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;
  scroll-snap-points-y: repeat(300px);

  >section {
    scroll-snap-align: start;
  }
}

