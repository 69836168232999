@import "../scss-variables";

header {
    background: $header-bg;

    #site-identification {
        background: $logo;
        width: 270px;
        height: 70px;
        background-size: contain;
        background-repeat: no-repeat;
    }

    h1 {
        position: absolute;
        left: -9999px;
        width: 0;
        overflow: hidden;
    }
}

.main-navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1400px;
    padding: 15px 25px;
    width: 100%;

    ul {
        align-items: center;
        display: flex;
        flex-direction: column;
        list-style: none;
        padding-inline-start: 0;
        width: 100%;


        li {
            border-bottom: 5px solid $menu-underline;
            margin: 0 5px;
            transition: $transition;
            width: 75%;

            &:hover {
                border-bottom: 5px solid $primary-color;

                a {
                    color:$primary-color;
                }
            }

            a {
                color: $menu-underline;
                display: block;
                font-weight: bold;
                padding: 25px 0;
                text-align: center;
                text-decoration: none;
                transition: $transition;
                width: 100%;

            }
        }
    }
}

header {
    .mobile-toggle {
        align-items: center;
        display: flex;

        button {
            background: $bg-color;
            border: 1px solid $font-color;
            border-radius: 5px;
            color: $font-color;
            cursor: pointer;
            font-size: 2rem;
            padding: 5px;
            width: 49px;

            &:hover {
                border-color: $primary-color;
                color: $primary-color;
            }
        }
    }


    .menuNav {
        height: 0;
        overflow: hidden;
        transition: $transition;

        &.showMenu {
            height: 370px;
        }
    }
}

footer {
    .mobile-toggle {
        display: none !important;
    }

    .menuNav {
        display: flex !important;
    }

    .copyright {
        text-align: center;
        width: 100%;
    }
}



@media (min-width: 960px) {

    .main-navigation {
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 1400px;
        padding: 15px 25px;
        width: 100%;

        ul {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            list-style: none;
            width: 55%;
        }
    }


    header {
        .mobile-toggle {
            display: none;
        }

        .menuNav {
            height: auto;

            &.showMenu {
                height: auto;
            }
        }

        #site-identification {
            width: 440px;
            height: 110px;
        }
    }

    footer {
        .main-navigation {
            ul {
                width: 100%;
            }
        }
    }

}