@import "../scss-variables";


.content-center-container {
    padding: 15px;

    .text-container {
        text-align: center;

        h2 {
            font-size: 2rem;
        }

        p {
            line-height: 1.7rem;
        }
    }
}



@media (min-width: 1400px) {
    .content-center-container {
        margin: 0 auto;
        max-width: 1400px;
        padding: 100px 25px;
    }


}