@import "../scss-variables";

.content-right-container {
    display: flex;
    flex-direction: column;
    margin: 15px;
    padding: 15px;

    .image {
        background-size: cover;
        height: 400px;
        background-position: center;
    }
    
}

@media (min-width: 768px) {
   .content-right-container {
       flex-direction: row;
       flex-wrap: nowrap;
       justify-content: flex-end;
       margin: 0;
       padding: 0;
       align-items: stretch;


       .text-container {
           margin-right: 25px;
           max-width: 40%;
           padding: 50px;
       }

       .image {
           margin-left: 25px;
           width: 40%;
           height: auto;
       }
   }
}

@media (min-width: 1400px) {
    .content-right-container {
        margin:0 auto;
        max-width: 1400px;
      
        .text-container {
            margin-right: 50px;
            max-width: 50%;
        }
 
        .image {
            margin-left: 50px;
            width: 40%;
        }
    }   
}