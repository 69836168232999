
.hero-container {
    background-size:cover;
    display: flex;
    height: 100vh;
    overflow: hidden;
    width: 100%;
    
    .hero-text-container {
        background: rgba(255,255,255, .8);
        box-shadow: 0 0  5px 5px rgba(255,255,255, .7);
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        height: 100%;
        margin: 0;
        padding: 25px;
        position: absolute;
        width: 100%;
        z-index: 5;

        h2 {
            font-size: 2rem;
        }
        h3 {
            width: 100%;
            text-align: center;
            padding-top: 50px;
            font-size: 1.5rem;
            font-style: italic;
        }

        p {
            padding: 5% 0;
        }

        .button-container {
            display:flex;
            justify-content: flex-end;
            padding: 25px 0;

        }
    }

  }

@media (min-width: 768px) {
    .hero-container {
        position: relative;
        
        .hero-text-container {
            display: flex;
            padding: 25px;
            position: absolute;
            width: 45%;

        }
    
      }
    
}


@media (min-width: 1400px) {
    .hero-container { 
       
        
        .hero-text-container {
            padding: 50px;


            h3{ 
                padding-top: 150px;
            }
        }
    
      }
    
}